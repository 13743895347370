import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { WithDestroyComponent } from '../../../core/abstract/abstract-with-destroy-component';
import { NonNullableFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormActionsService } from '@early-stage/components';
import { SnackbarService } from '../../../utility-modules/snackbar/services/snackbar.service';
import { ModalRef } from '../../../utility-modules/modals/services/modal-ref';
import { takeUntil } from 'rxjs/operators';
import { ModalConfig } from '../../../utility-modules/modals/services/modal-config';
import { FormInModalComponent } from '../../components/form-in-modal/form-in-modal.component';
import { IGroupColor, IGroupListItem } from '../../../core/models/group.interface';
import { GroupsService } from '../../../core/services/groups.service';
import { Select, Store } from '@ngxs/store';
import { LoadGroupColors } from '../../../core/store/dictionary/dictionary.actions';
import { DictionaryState } from '../../../core/store/dictionary/dictionary.state';
import { Observable } from 'rxjs';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormFieldComponent } from '@early-stage/components';

@Component({
    selector: 'es-color-picker-modal',
    templateUrl: './color-picker-modal.component.html',
    styleUrls: ['./color-picker-modal.component.scss'],
    providers: [FormActionsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormInModalComponent, FormsModule, ReactiveFormsModule, NgIf, ColorSelectorComponent, AsyncPipe, FormFieldComponent],
})
export class ColorPickerModalComponent extends WithDestroyComponent {
    @ViewChild(FormInModalComponent, { static: true }) public formInModal: FormInModalComponent;

    public formGroup = this._fb.group({
        color: this._fb.control<number>(null, [Validators.required]),
    });

    public selectedGroup: IGroupListItem;

    @Select(DictionaryState.groupColors) public groupColors$: Observable<IGroupColor[]>;

    constructor(
        private _fb: NonNullableFormBuilder,
        private _groupService: GroupsService,
        public formActions: FormActionsService,
        public modalConfig: ModalConfig<{
            group: IGroupListItem;
        }>,
        private snackbar: SnackbarService,
        private _modalRef: ModalRef,
        private _store: Store
    ) {
        super();
    }

    // tslint:disable-next-line:use-lifecycle-interface
    public ngOnInit(): void {
        this.formActions.connect(this.formGroup);

        this.formActions.submitSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.snackbar.add({
                title: 'Super!',
                text: 'Kolor dziennika został zmieniony',
                type: 'success',
            });

            this._modalRef.close(true);
        });

        this.selectedGroup = this.modalConfig.data.group;
        this.formGroup.patchValue({
            color: this.selectedGroup.color.id,
        });
        this._store.dispatch(new LoadGroupColors());
    }

    // tslint:disable-next-line:use-lifecycle-interface
    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.formActions.disconnect();
    }

    public onSubmit(): void {
        this.formActions.trySubmit(() => {
            return this._groupService.changeGroupColor(this.selectedGroup.id, this.formGroup.getRawValue());
        });
    }
}
