<div class="color-selector" [formGroup]="parentFormGroup">
    <label *ngFor="let color of colors" class="color-option">
        <input type="radio" name="color" [value]="color.id" formControlName="color" />
        <div class="color-block" [style.background-color]="color.background">
            <div class="color-block-selected-indicator">
                <es-icon icon="check-circle-filled" [width]="24" [height]="24"></es-icon>
            </div>
        </div>
    </label>
</div>
