<es-form-in-modal
    (submit)="onSubmit()"
    [formGroup]="formGroup"
    [submitText]="'Zapisz'"
    [errorObservable]="formActions.errorMessage$"
    [formState]="formActions.formState$ | async">
    <ng-container modal-header>
        <div class="modal__wrapper">
            <div class="list is-tiny">
                <h2>Zmień kolor dziennika</h2>
                <p class="text-color-grey-700">{{ selectedGroup.fullName }}</p>
            </div>
        </div>
    </ng-container>

    <div modal-form-content>
        <div class="modal__wrapper">
            <div class="inputs-list" *ngIf="groupColors$ | async as groupColors; else loading">
                <esc-form-field class="ofr-input">
                    <es-color-selector [colors]="groupColors" [parentFormGroup]="formGroup"></es-color-selector>
                </esc-form-field>
            </div>
        </div>
    </div>
</es-form-in-modal>

<ng-template #loading>
    <div class="colors-loader">
        <div class="loader-icon"></div>
    </div>
</ng-template>
